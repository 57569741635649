// eslint-disable-next-line no-unused-vars
const KonvertRegistrationMnsJson = (_this) => {
  return {
    countCells: 18,
    orgMassive: [
      {
        dataField: 'num',
        cols: 1,
        rows: 1,
        text: 'Номер',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'konvertRegistrationJsonNum',
        styles: '',
        headerClass: '',
        headerStyles: `display: none`,
      },
      {
        dataField: 'orgInfo',
        cols: 4,
        rows: 1,
        text: 'Информация',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantAdminJsonInfo',
        styles: '',
        headerClass: '',
        headerStyles: `display: none`,
      },
      {
        dataField: 'ugrulUstav',
        text: '',
        cols: 2,
        rows: 1,
        variable: true,
        class: 'konvertRegistrationJsonUgrulUstav',
        styles: '',
        headerClass: '',
        headerStyles: `display: none`,
      },
      {
        dataField: 'fio',
        cols: 3,
        rows: 1,
        text: 'ФИО',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantAdminJsonFio',
        styles: '',
        headerClass: '',
        headerStyles: `display: none`,
      },
      {
        dataField: 'stpType',
        cols: 4,
        rows: 1,
        text: 'Тип стипендии',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantAdminJsonStpType',
        styles: '',
        headerClass: '',
        headerStyles: `display: none`,
      },
      {
        dataField: 'konvert',
        cols: 2,
        rows: 1,
        text: 'Конверт',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'applicantAdminJsonKonvert',
        styles: '',
        headerClass: '',
        headerStyles: `display: none`,
      },
      {
        dataField: 'noScanList',
        text: '',
        cols: 2,
        rows: 1,
        variable: true,
        styles: 'border-left: none; display: flex; flex-direction: row',
        // styles: '',
        class: 'konvertRegistrationJsonNoScanList',
        headerClass: '',
        headerStyles: `display: none`,
      },
    ],
  };
}

export default KonvertRegistrationMnsJson